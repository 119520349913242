import React, { useEffect, useState } from 'react';
import "./blog.scss";
import "./wordpressSyling.scss";
import Navbar from '../landing/header/Navbar';
import Build from '../landing/Buildthefuture/Build';
import Footer from '../landing/footer/Footer';
import { Link } from 'react-router-dom';
import axios from 'axios';
import Environment from '../../utils/Environment';
import Loader from '../../utils/loader';

const Blog = () => {

    useEffect(() => {
        window.scrollTo(0, 0)
    }, []);

    function toCapitalize(str) {
        if (!str) return str;
        return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
    }
    const [loader, setLoader] = useState(false);


    const changeDateFormate = (createdAt) => {
        const originalDate = new Date(createdAt);

        // Format the date and time
        const formattedDate = originalDate.toLocaleString('en-US', {
            month: 'short',
            day: 'numeric',
            year: 'numeric',
            hour: 'numeric',
            minute: 'numeric',
            second: 'numeric',
            hour12: true, // Use 12-hour clock, you can set it to false for 24-hour clock
        });

        // Return the formatted date and time
        return formattedDate;
    }


    const [posts, setPosts] = useState([]);

    // console.log(posts[0]?.uagb_featured_image_src?.full[0], "aasdfgh");

    // const getAllPosts = () => {
    //     axios.get(Environment.backendUrl + `wp/v2/posts`)
    //         .then((response) => {
    //             console.log("🚀 ~ .then ~ getAllPosts: video okokok", response.data);
    //             setPosts(response?.data)
    //         }).catch((error) => {
    //             console.log(error, 'getAllPosts error');
    //         })
    // }

    // useEffect(() => {
    //     getAllPosts();
    // }, []);



    const [recentArticles, setRecentArticles] = useState([]);
    const [otherArticles, setOtherArticles] = useState([]);

    // console.log(recentArticles, "recentArticles artiles");
    // console.log(otherArticles, "otherArticles artiles");


    useEffect(() => {
        const fetchArticles = async () => {

            try {
                setLoader(true); // Start the loader
                const response = await axios.get(`${Environment.backendUrl}wp/v2/posts`); // Replace with your API endpoint
                console.log(response, 'response, artiles');

                if (response) {
                    const delay = ms => new Promise(resolve => setTimeout(resolve, ms));
                    await delay(2000);

                    const articles = response.data;
                    // Separate the articles
                    const newRecentArticles = articles.slice(0, 4); // First 4 articles
                    const newOtherArticles = articles.slice(4); // Remaining articles

                    // Update state
                    setRecentArticles(newRecentArticles);
                    setOtherArticles(newOtherArticles);
                }
            } catch (error) {
                setLoader(false);
                console.error('Error fetching articles:', error);
            } finally {
                setLoader(false);
            }
        };

        fetchArticles();
    }, []);





    return (
        <>
            {loader && <Loader />}

            <Navbar />
            <section className="blog-section">
                <div className="custom-container">
                    <div className="featured-blog">
                        <div className="left-side">
                            <div className="main-heading">
                                <Link to={`/blogdetail/${recentArticles[0]?.slug}`}>
                                    <h4>{recentArticles[0]?.title?.rendered}</h4>
                                </Link>
                                <p><span>{toCapitalize(recentArticles[0]?.class_list[7]?.slice(9))}</span> | {changeDateFormate(recentArticles[0]?.date)}</p>
                            </div>
                            <div className="main-img">
                                <Link to={`/blogdetail/${recentArticles[0]?.slug}`}>
                                    <img src={recentArticles[0]?.uagb_featured_image_src?.full[0]} alt="img" className='img-fluid' />
                                </Link>
                            </div>
                        </div>
                        <div className="right-side">
                            {
                                recentArticles?.slice(1)?.map((post, index) => {
                                    console.log(post?.uagb_featured_image_src?.full[0], "post image");

                                    return (
                                        <Link to={`/blogdetail/${post?.slug}`}>
                                            <div className="single-blog" key={index}>
                                                <div className="text">
                                                    <h6>{post?.title?.rendered}</h6>
                                                    <p>{changeDateFormate(post?.date)}</p>
                                                </div>
                                                <div className="right-img">
                                                    <img src={post?.uagb_featured_image_src?.full[0]} alt="img" className='img-fluid' />
                                                </div>
                                            </div>
                                        </Link>
                                    )
                                })
                            }
                            {/* <div className="single-blog">
                                <div className="text">
                                    <h6>{posts[2]?.title?.rendered}</h6>
                                    <p>{changeDateFormate(posts[2]?.date)}</p>
                                </div>
                                <div className="right-img">
                                    <img src={posts[2]?.uagb_featured_image_src?.full[0]} alt="img" className='img-fluid' />
                                </div>
                            </div>
                            <div className="single-blog">
                                <div className="text">
                                    <h6>{posts[3]?.title?.rendered}</h6>
                                    <p>{changeDateFormate(posts[3]?.date)}</p>
                                </div>
                                <div className="right-img">
                                    <img src={posts[3]?.uagb_featured_image_src?.full[0]} alt="img" className='img-fluid' />
                                </div>
                            </div> */}
                        </div>
                    </div>
                    <div className="latest-article">
                        <div className="main-heading">
                            <h4>Latest Articles</h4>
                        </div>
                        <div className="bottom-cards">
                            {otherArticles.map((post, index) => {
                                console.log(post?.date, "asd");

                                return (
                                    <Link to={`/blogdetail/${post?.id}`} key={index}>
                                        <div className="single-card">
                                            <div className="main-img">
                                                <img src={post?.uagb_featured_image_src ? post?.uagb_featured_image_src?.full[0] : "\assets\blog\img1.png"} alt="img" className='img-fluid' />
                                            </div>
                                            <div className="bottom-text">
                                                <h5>{post?.title?.rendered}</h5>
                                                <div className="twice-text">
                                                    <p>{changeDateFormate(post?.date)}</p>
                                                    <Link to={`/blogdetail/${post?.id}`}>
                                                        Read
                                                        <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                                                            <path d="M1.33325 5.99998H12.9999M12.9999 5.99998L8.33325 1.33331M12.9999 5.99998L8.33325 10.6666" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                                        </svg>
                                                    </Link>
                                                </div>
                                            </div>
                                        </div>
                                    </Link>
                                )
                            })}
                        </div>
                    </div>
                </div>
            </section>
            <Build />
            <Footer />
        </>
    )
}

export default Blog
