import { Link, useLocation } from "react-router-dom";
import "./navbar.scss";
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useState } from "react";

const Navbar = () => {
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const router = useLocation();
  return (
    <>
      <section className="main-navbar">
        <div className="custom-container">
          <nav class="navbar navbar-expand-xl">
            <div class="container-fluid">
              <Link class="navbar-brand" to="/"><img src="\logo.svg" alt="img" className="img-fluid" /></Link>
              <button class="navbar-toggler" type="button" onClick={handleShow}>
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                  <path d="M5 17H13M5 12H19M11 7H19" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                </svg>
              </button>
              <div class="collapse navbar-collapse justify-content-end" id="navbarSupportedContent">
                <ul class="navbar-nav me-auto mb-2 mb-lg-0">
                  <li class="nav-item">
                    <a class="nav-link" aria-current="page" href="#">Products</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">Developers</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">Resources</a>
                  </li>
                  <li class="nav-item">
                    <a class="nav-link" href="#">Community</a>
                  </li>
                  <li class="nav-item">
                    <Link to="/blog" class={router.pathname === "/blog" ? "nav-link active" : "nav-link"}>Blog</Link>
                  </li>
                </ul>
                <a href="#" className="transparent-btn hover-button">
                  <span className="button-text">Contact</span>
                  <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g clip-path="url(#clip0_261_17)">
                      <path d="M5.25 9H12.75M12.75 9L9.75 6M12.75 9L9.75 12" stroke="#26C782" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_261_17">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg></span>
                  <span className="new-button-text">Contact</span>
                  <span className="new-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
                    <g clip-path="url(#clip0_261_17)">
                      <path d="M5.25 9H12.75M12.75 9L9.75 6M12.75 9L9.75 12" stroke="#26C782" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                    </g>
                    <defs>
                      <clipPath id="clip0_261_17">
                        <rect width="18" height="18" fill="white" />
                      </clipPath>
                    </defs>
                  </svg></span>
                </a>

              </div>
            </div>
          </nav>
        </div>
        <div class="border-bottom-line"></div>
      </section>

      <Offcanvas className="mobile-offcanvas" show={show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title> <a href="/"><img src="\logo.svg" alt="img" className="img-fluid" /></a> </Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul class="navbar-nav me-auto mb-2 mb-lg-0">
            <li class="nav-item">
              <a class="nav-link" aria-current="page" href="#">Products</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Developers</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Resources</a>
            </li>
            <li class="nav-item">
              <a class="nav-link" href="#">Community</a>
            </li>
            <li class="nav-item">
              <Link class={router.pathname === "/blog" ? "nav-link active" : "nav-link"} to="/blog">Blog</Link>
            </li>
          </ul>
          <a href="#" className="transparent-btn hover-button">
            <span className="button-text">Contact</span>
            <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <g clip-path="url(#clip0_261_17)">
                <path d="M5.25 9H12.75M12.75 9L9.75 6M12.75 9L9.75 12" stroke="#26C782" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_261_17">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg></span>
            <span className="new-button-text" style={{marginBottom: "12px"}}>Contact</span>
            <span className="new-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18" fill="none">
              <g clip-path="url(#clip0_261_17)">
                <path d="M5.25 9H12.75M12.75 9L9.75 6M12.75 9L9.75 12" stroke="#26C782" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
              </g>
              <defs>
                <clipPath id="clip0_261_17">
                  <rect width="18" height="18" fill="white" />
                </clipPath>
              </defs>
            </svg></span>
          </a>
        </Offcanvas.Body>
      </Offcanvas>


    </>
  );
};

export default Navbar;
