
import React, { lazy, Suspense, useEffect, useRef, useState } from "react";
import "./banner.scss";
import gsap from 'gsap';
const Spline = lazy(() => import("@splinetool/react-spline"));




const Banner = () => {
  const textRefs = useRef([]);

  useEffect(() => {
    textRefs.current.forEach((text) => {
      gsap.fromTo(
        text,
        { y: '120%' },
        {
          y: '0%',
          duration: 3,
          ease: 'power3.out',
        }
      );
    });
  }, []);

  const words = ["RWA", "DEEPIN", "NFT", "DEFI"];
  const [currentWord, setCurrentWord] = useState("DEFI"); 
  const [wordIndex, setWordIndex] = useState(0); 
  const [charIndex, setCharIndex] = useState(0); 
  const [isDeleting, setIsDeleting] = useState(false); 
  const [isInitialDelayDone, setIsInitialDelayDone] = useState(false);

  const typingSpeed = 200; 
  const deletingSpeed = 150; 
  const delayBeforeDelete = 1500;
  const delayBetweenWords = 500; 
  const initialDelay = 2000; 

  useEffect(() => {
   
    const initialDelayTimeout = setTimeout(() => {
      setIsInitialDelayDone(true); 
    }, initialDelay);

    return () => clearTimeout(initialDelayTimeout); 
  }, []);

  useEffect(() => {
    if (!isInitialDelayDone) return; 

    let timeout;

    if (!isDeleting && charIndex < words[wordIndex].length) {
    
      timeout = setTimeout(() => {
        setCurrentWord(words[wordIndex].substring(0, charIndex + 1));
        setCharIndex(charIndex + 1);
      }, typingSpeed);
    } else if (!isDeleting && charIndex === words[wordIndex].length) {

      timeout = setTimeout(() => {
        setIsDeleting(true);
      }, delayBeforeDelete);
    } else if (isDeleting && charIndex > 0) {
    
      timeout = setTimeout(() => {
        setCurrentWord(words[wordIndex].substring(0, charIndex - 1));
        setCharIndex(charIndex - 1);
      }, deletingSpeed);
    } else if (isDeleting && charIndex === 0) {

      timeout = setTimeout(() => {
        setIsDeleting(false);
        setWordIndex((prevIndex) => (prevIndex + 1) % words.length); 
      }, delayBetweenWords);
    }

    return () => clearTimeout(timeout);
  }, [charIndex, isDeleting, wordIndex, words, isInitialDelayDone]);

  return (
    <>

      <section className="main-banner">
        <div className="custom-container">
          {
            window.innerWidth > 1024 ? <Suspense fallback={<></>}>
              <Spline className="banner-spline" scene="https://prod.spline.design/layyci6m-S2Qos2N/scene.splinecode" />
            </Suspense> : <video
              id="myVideo"
              className="video-player banner-spline"
              muted="muted"
              playsinline="playsinline"
              autoPlay
              loop
              class="supervideo zjcbsjbc banner-spline"
            >
              <source
                src="\assets\banner\spline-mobile.mp4"
                type="video/mp4"
              />
            </video>
          }
          <div className="left-content">
            {/* <h2 ref={textRef}>Powerful for developers. Fast for everyone.</h2> */}
            <div style={{ overflow: 'hidden', position: 'relative' }}>
              <h2
                ref={(el) => (textRefs.current[0] = el)}
                style={{
                  margin: 0,
                  transform: 'translateY(120%)',
                }}
              >
               Easy to build,  
              </h2>
            </div>
            <div style={{ overflow: 'hidden', position: 'relative' }}>
              <h2
                ref={(el) => (textRefs.current[1] = el)}
                style={{
                  margin: 0,
                  transform: 'translateY(120%)',
                }}
              >
                scalable and fast
              </h2>
            </div>
            <div style={{ overflow: 'hidden', position: 'relative' }}>
              <h2
                ref={(el) => (textRefs.current[2] = el)}
                style={{
                  margin: 0,
                  transform: 'translateY(120%)',
                }}
              >
                for <span>{currentWord}</span>
              </h2>
            </div>
            <p>Simple setup, aiming for mass adoption of blockchain for the next billion users</p>
            <a href="#" className="green-btn hover-button">
              <span className="button-text">Start Building</span>
              <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <g clip-path="url(#clip0_261_28)">
                  <path d="M8.16669 14H19.8334M19.8334 14L15.1667 9.33331M19.8334 14L15.1667 18.6666" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_261_28">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                </defs>
              </svg></span>
              <span className="new-button-text" style={{ marginBottom: "12px" }}>Start Building</span>
              <span className="new-arrow"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                <g clip-path="url(#clip0_261_28)">
                  <path d="M8.16669 14H19.8334M19.8334 14L15.1667 9.33331M19.8334 14L15.1667 18.6666" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                </g>
                <defs>
                  <clipPath id="clip0_261_28">
                    <rect width="28" height="28" fill="white" />
                  </clipPath>
                </defs>
              </svg></span></a>
          </div>
        </div>
      </section>
    </>
  );
};

export default Banner;
