import React, { useEffect, useRef } from 'react'
import "./build.scss"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Build = () => {
    const textRefs = useRef([]);

    useEffect(() => {
        textRefs.current.forEach((text) => {
            gsap.fromTo(
                text,
                { y: '120%' },
                {
                    y: '0%',
                    duration: 2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: text,
                        start: 'top 80%',
                        end: 'top 20%',
                        once: true
                    },
                }
            );
        });
    }, []);
    return (
        <>
            <section className="build-section">
                <img src="\assets\build\build-bg.png" alt="img" className='img-fluid build-bg' />
                <div className="custom-container">
                    <div className="main-content">
                        <div>
                            <div style={{ overflow: 'hidden', position: 'relative' }}>
                                <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[0] = el)}>Let's build the future of</h4>
                            </div>
                            <div style={{ overflow: 'hidden', position: 'relative' }}>
                                <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[1] = el)}>blockchain together.</h4>
                            </div>
                        </div>
                        <a href="#" className='white-btn hover-button'>
                            <span className="button-text">Get in Touch</span>
                            <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <g clip-path="url(#clip0_261_9)">
                                    <path d="M8.16669 14H19.8334M19.8334 14L15.1667 9.33333M19.8334 14L15.1667 18.6667" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_261_9">
                                        <rect width="28" height="28" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg></span>
                            <span className="new-button-text" style={{ marginBottom: "12px" }}>Get in Touch</span>
                            <span className="new-arrow" style={{ right: "14px" }}><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <g clip-path="url(#clip0_261_9)">
                                    <path d="M8.16669 14H19.8334M19.8334 14L15.1667 9.33333M19.8334 14L15.1667 18.6667" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_261_9">
                                        <rect width="28" height="28" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg></span>
                        </a>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Build
