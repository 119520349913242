import '../../app.scss';
import React, { useEffect } from "react";
import Banner from './main-banner/Banner.js';
import Navbar from './header/Navbar';
import Footer from './footer/Footer.js';
import NewUniverse from './NewUniverse/NewUniverse.js';
import Features from './Features/Features.js';
import OurThesis from './OurThesis/OurThesis.js';
import Partners from './Partners/Partners.js';
import Updates from './Updates/Updates.js';
import Build from './Buildthefuture/Build.js';
import Roadmap from './Roadmap/Roadmap.js';



function Landing() {
  useEffect(() => {
    window.scrollTo(0, 0)
  }, [])


  return (
    <>
      <Navbar />
      <Banner />
      <NewUniverse />
      <Features />
      <OurThesis />
      <Roadmap />
      <Partners />
      <Updates />
      <Build />
      <Footer />
    </>
  );
}

export default Landing;