import React, { useEffect, useRef, useState } from 'react'
import "./updates.scss"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import Environment from '../../../utils/Environment';
import axios from 'axios';
import { Link } from 'react-router-dom';

gsap.registerPlugin(ScrollTrigger);

const Updates = () => {

    const [posts, setPosts] = useState([]);

    const [circleStyle, setCircleStyle] = useState({
        opacity: 0,
        left: '0',
        top: '0',
    });

    const [lastPosition, setLastPosition] = useState({ left: '0', top: '0' });

    const handleMouseMove = (e) => {
        const { left, top } = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - left;
        const y = e.clientY - top;

        setCircleStyle({
            left: `${x}px`,
            top: `${y}px`,
            opacity: 0.15,
        });


        setLastPosition({ left: `${x}px`, top: `${y}px` });
    };

    const handleMouseLeave = () => {
        setCircleStyle((prevStyle) => ({
            ...prevStyle,
            left: lastPosition.left,
            top: lastPosition.top,
            opacity: 0.15,
        }));
    };

    const handleMouseEnter = () => {
        setCircleStyle((prevStyle) => ({
            ...prevStyle,
            left: lastPosition.left,
            top: lastPosition.top,
            opacity: 0.15,
        }));
    };

    const textRefs = useRef([]);

    useEffect(() => {
        textRefs.current.forEach((text) => {
            gsap.fromTo(
                text,
                { y: '120%' },
                {
                    y: '0%',
                    duration: 2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: text,
                        start: 'top 80%',
                        end: 'top 20%',
                        once: true
                    },
                }
            );
        });
    }, []);


    const getAllPosts = () => {
        axios.get(Environment.backendUrl + `wp/v2/posts`)
            .then((response) => {
                console.log("🚀 ~ .then ~ getAllPosts: video okokok", response.data);
                setPosts(response?.data)
            }).catch((error) => {
                console.log(error, 'getAllPosts error');
            })
    }

    useEffect(() => {
        getAllPosts();
    }, []);

    return (
        <>
            <section className="Updates-section">
                <img src="\assets\updates\updates-bg.png" alt="img" className='img-fluid updates-bg' />
                <div className="custom-container">
                    <div className="main-heading">
                        <div className="inside-left">
                            <p>News & Updates</p>
                            <div style={{ overflow: 'hidden', position: 'relative' }}>
                                <h4 style={{
                                    margin: 0,
                                    transform: 'translateY(120%)',
                                }} ref={(el) => (textRefs.current[0] = el)}>Latest Vion</h4>
                            </div>
                            <div style={{ overflow: 'hidden', position: 'relative' }}>
                                <h4 style={{
                                    margin: 0,
                                    transform: 'translateY(120%)',
                                }} ref={(el) => (textRefs.current[1] = el)}>Network Updates</h4>
                            </div>
                        </div>
                        <Link to="/blog" className="green-btn hover-button">
                            <span className="button-text">See All</span>
                            <span className="arrow"><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <g clip-path="url(#clip0_261_28)">
                                    <path d="M8.16669 14H19.8334M19.8334 14L15.1667 9.33331M19.8334 14L15.1667 18.6666" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_261_28">
                                        <rect width="28" height="28" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg></span>
                            <span className="new-button-text" style={{ marginBottom: "12px" }}>See All</span>
                            <span className="new-arrow" style={{ right: "14px" }}><svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28" fill="none">
                                <g clip-path="url(#clip0_261_28)">
                                    <path d="M8.16669 14H19.8334M19.8334 14L15.1667 9.33331M19.8334 14L15.1667 18.6666" stroke="#00110A" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                </g>
                                <defs>
                                    <clipPath id="clip0_261_28">
                                        <rect width="28" height="28" fill="white" />
                                    </clipPath>
                                </defs>
                            </svg></span>
                        </Link>
                    </div>
                    <div className="bottom-cards" onMouseMove={handleMouseMove} onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        <div
                            className="shadow-circle"
                            style={{
                                left: circleStyle.left,
                                top: circleStyle.top,
                                opacity: circleStyle.opacity,
                            }}
                        />

                        {posts.map((post) => {
                            console.log(post?.slug, "sdfsd");
                            return (
                                <div key={post.id} className="single-card">
                                    <div className="main-img">
                                        <img
                                            src={post?.uagb_featured_image_src ? post?.uagb_featured_image_src?.full[0] : "/assets/updates/img1.png"}
                                            alt={post.title.rendered}
                                            className='img-fluid'
                                        />
                                    </div>
                                    <div className="card-content">
                                        <h6>{post.title.rendered}</h6>
                                        <div className="twice-text">
                                            <p>{new Date(post.date).toLocaleDateString()}</p>
                                            <Link to={`/blogdetail/${post?.slug}`}>View
                                                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                                                    <path d="M1.33331 5.99992H13M13 5.99992L8.33331 1.33325M13 5.99992L8.33331 10.6666"
                                                        stroke="#2DD98F"
                                                        strokeWidth="1.5"
                                                        strokeLinecap="round"
                                                        strokeLinejoin="round"
                                                    />
                                                </svg>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            )
                        })}

                        {/* <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\updates\img1.png" alt="img" className='img-fluid' />
                            </div>
                            <div className="card-content">
                                <h6>An introduction to Vion Network Blockchain</h6>
                                <div className="twice-text">
                                    <p>July 24, 2024</p>
                                    <a href="#">View <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                                        <path d="M1.33331 5.99992H13M13 5.99992L8.33331 1.33325M13 5.99992L8.33331 10.6666" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                        <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\updates\img2.png" alt="img" className='img-fluid' />
                            </div>
                            <div className="card-content">
                                <h6>Unveiling the partnership Vion x Polkadot</h6>
                                <div className="twice-text">
                                    <p>July 24, 2024</p>
                                    <a href="#">View <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                                        <path d="M1.33331 5.99992H13M13 5.99992L8.33331 1.33325M13 5.99992L8.33331 10.6666" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></a>
                                </div>
                            </div>
                        </div>
                        <div className="single-card">
                            <div className="main-img">
                                <img src="\assets\updates\img3.png" alt="img" className='img-fluid' />
                            </div>
                            <div className="card-content">
                                <h6>Stellar Development Foundation Q2 2024 in Review</h6>
                                <div className="twice-text">
                                    <p>July 24, 2024</p>
                                    <a href="#">View <svg xmlns="http://www.w3.org/2000/svg" width="14" height="12" viewBox="0 0 14 12" fill="none">
                                        <path d="M1.33331 5.99992H13M13 5.99992L8.33331 1.33325M13 5.99992L8.33331 10.6666" stroke="#2DD98F" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg></a>
                                </div>
                            </div>
                        </div> */}
                    </div>
                </div>
            </section>
        </>
    )
}

export default Updates
