import React, { useEffect, useRef, useState } from 'react'
import "./features.scss"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Features = () => {
    const [circleStyle, setCircleStyle] = useState({
        opacity: 0,
        left: '0',
        top: '0',
    });

    const [lastPosition, setLastPosition] = useState({ left: '0', top: '0' });

    const handleMouseMove = (e) => {
        const { left, top } = e.currentTarget.getBoundingClientRect();
        const x = e.clientX - left;
        const y = e.clientY - top;

        setCircleStyle({
            left: `${x}px`,
            top: `${y}px`,
            opacity: 0.15,
        });


        setLastPosition({ left: `${x}px`, top: `${y}px` });
    };

    const handleMouseLeave = () => {
        setCircleStyle((prevStyle) => ({
            ...prevStyle,
            left: lastPosition.left,
            top: lastPosition.top,
            opacity: 0.15,
        }));
    };

    const handleMouseEnter = () => {
        setCircleStyle((prevStyle) => ({
            ...prevStyle,
            left: lastPosition.left,
            top: lastPosition.top,
            opacity: 0.15,
        }));
    };

    const textRefs = useRef([]);

    useEffect(() => {
        textRefs.current.forEach((text) => {
            gsap.fromTo(
                text,
                { y: '120%' },
                {
                    y: '0%',
                    duration: 2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: text,
                        start: 'top 80%',
                        end: 'top 20%',
                        once: true
                    },
                }
            );
        });
    }, []);
    return (
        <>
            <section className="features-section">
                <img src="\assets\features\features-bg.png" alt="img" className='img-fluid features-bg' />
                <div className="custom-container">
                    <div className="main-heading">
                        <p>Vion Features</p>
                        <div style={{ overflow: 'hidden', position: 'relative' }}>
                            <h4 style={{
                                margin: 0,
                                transform: 'translateY(120%)',
                            }} ref={(el) => (textRefs.current[0] = el)}>Made for mass</h4>
                        </div>
                        <div style={{ overflow: 'hidden', position: 'relative' }}>
                            <h4 style={{
                                margin: 0,
                                transform: 'translateY(120%)',
                            }} ref={(el) => (textRefs.current[1] = el)}>adoption.</h4>
                        </div>
                    </div>
                    <div className="parent" onMouseMove={handleMouseMove} onMouseEnter={handleMouseEnter}
                        onMouseLeave={handleMouseLeave}>
                        <div
                            className="shadow-circle"
                            style={{
                                left: circleStyle.left,
                                top: circleStyle.top,
                                opacity: circleStyle.opacity,
                            }}
                        />
                        <div className="left-cards">
                            <div className="single-card">
                                <img src="\assets\features\card-bg-horizontal.png" alt="img" className='img-fluid card-bg-horizontal' />
                                <div className="inside-left">
                                    <div style={{ overflow: 'hidden', position: 'relative' }}>
                                        <h5 style={{
                                            margin: 0,
                                            transform: 'translateY(120%)',
                                        }} ref={(el) => (textRefs.current[2] = el)}>Fast</h5>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt.</p>
                                    <div className="price-div">
                                        <div style={{ overflow: 'hidden', position: 'relative' }}>
                                            <h6 style={{
                                                margin: 0,
                                                transform: 'translateY(120%)',
                                            }} ref={(el) => (textRefs.current[3] = el)}>3,783</h6>
                                        </div>
                                        <p>Transactions per Second</p>
                                    </div>
                                </div>
                                <div className="img-wrapper">
                                    <img src="\assets\features\fast.png" alt="img" className='img-fluid' />
                                </div>
                            </div>
                            <div className="single-card">
                                <img src="\assets\features\card-bg-horizontal.png" alt="img" className='img-fluid card-bg-horizontal' />
                                <div className="inside-left">
                                    <div style={{ overflow: 'hidden', position: 'relative' }}>
                                        <h5 style={{
                                            margin: 0,
                                            transform: 'translateY(120%)',
                                        }} ref={(el) => (textRefs.current[4] = el)}>In-depth</h5>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt.</p>
                                    <div className="price-div">
                                        <div style={{ overflow: 'hidden', position: 'relative' }}>
                                            <h6 style={{
                                                margin: 0,
                                                transform: 'translateY(120%)',
                                            }} ref={(el) => (textRefs.current[7] = el)}>378,697,583</h6>
                                        </div>
                                        <p>Total Transactions</p>
                                    </div>
                                </div>
                                <div className="img-wrapper">
                                    <img src="\assets\features\indepth.png" alt="img" className='img-fluid' />
                                </div>
                            </div>
                        </div>
                        <div className="right-card">
                            <div className="single-card">
                                <img src="\assets\features\card-bg-vertical.png" alt="img" className='img-fluid card-bg-vertical' />
                                <div className="img-wrapper">
                                    <img src="\assets\features\decentralized.png" alt="img" className='img-fluid' />
                                </div>
                                <div className="bottom-text">
                                    <div style={{ overflow: 'hidden', position: 'relative' }}>
                                        <h5 style={{
                                            margin: 0,
                                            transform: 'translateY(120%)',
                                        }} ref={(el) => (textRefs.current[6] = el)}>Decentralized</h5>
                                    </div>
                                    <p>Lorem ipsum dolor sit amet est accusamus dolores qui delectus quisquam sed nostrum dolorum aut consequatur deserunt.</p>
                                    <div className="price-div">
                                        <div style={{ overflow: 'hidden', position: 'relative' }}>
                                            <h6 style={{
                                                margin: 0,
                                                transform: 'translateY(120%)',
                                            }} ref={(el) => (textRefs.current[8] = el)}>1,498</h6>
                                        </div>
                                        <p>Validator Nodes</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Features
