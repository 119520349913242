import React, { useEffect, useRef } from 'react'
import "./roadmap.scss"
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';

gsap.registerPlugin(ScrollTrigger);

const Roadmap = () => {
    const textRefslogo = useRef([]);

    useEffect(() => {
        textRefslogo.current.forEach((element) => {
            gsap.fromTo(
                element,
                { rotationX: 90, opacity: 0 },
                {
                    rotationX: 0,
                    opacity: 1,
                    duration: 2,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 80%',
                        once: true,
                    },
                }
            );
        });
    }, []);

    const textRefs = useRef([]);

    useEffect(() => {
        textRefs.current.forEach((text) => {
            gsap.fromTo(
                text,
                { y: '100%' },
                {
                    y: '0%',
                    duration: 2,
                    ease: 'power3.out',
                    scrollTrigger: {
                        trigger: text,
                        start: 'top 80%',
                        end: 'top 20%',
                        once: true
                    },
                }
            );
        });
    }, []);

    const imageRefs = useRef([]);

    useEffect(() => {
        imageRefs.current.forEach((element) => {
            gsap.fromTo(
                element,
                { opacity: 0 },
                {
                    opacity: 1,
                    duration: 2.5,
                    ease: 'power2.out',
                    scrollTrigger: {
                        trigger: element,
                        start: 'top 80%',
                        once: true,
                    },
                }
            );
        });
    }, []);
    return (
        <>
            <section className="our-partners roadmap-section">
                <img ref={(el) => (imageRefs.current[0] = el)} src="\assets\roadmap\roadmap-bg.png" alt="img" className='img-fluid partners-bg' />
                <img ref={(el) => (imageRefs.current[1] = el)} src="\assets\partners\partners-bg-mobile.png" alt="img" className='img-fluid partners-bg d-none partners-bg-mobile' />
                <div className="custom-container">
                    <div className="main-heading">
                        <div style={{ overflow: 'hidden', position: 'relative' }}>
                            <h4 style={{
                                margin: 0,
                                transform: 'translateY(120%)',
                            }} ref={(el) => (textRefs.current[0] = el)}>Roadmap to Reach the</h4>
                        </div>
                        <div style={{ overflow: 'hidden', position: 'relative' }}>
                            <h4 style={{
                                margin: 0,
                                transform: 'translateY(120%)',
                            }} ref={(el) => (textRefs.current[1] = el)}>Galaxies</h4>
                        </div>
                    </div>
                    <div className="bottom-roadmap">
                        <div className="left-heading">
                            <div className="first-heading common-heading-style">
                                <p>PHASE 1</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[2] = el)}>Q3 - 2024</h4>
                                </div>
                            </div>
                            <div className="sec-heading common-heading-style">
                                <p>PHASE 2</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[3] = el)}>Q4 - 2024</h4>
                                </div>
                            </div>
                            <div className="third-heading common-heading-style">
                                <p>PHASE 3</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[4] = el)}>Q1 - 2025</h4>
                                </div>
                            </div>
                            <div className="sec-heading common-heading-style">
                                <p>PHASE 4</p>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[5] = el)}>Q2 - 2025</h4>
                                </div>
                            </div>
                        </div>
                        <div className="middle-line">
                            <img src="\assets\roadmap\line-img.png" alt="img" className='img-fluid' />
                        </div>
                        <div className="right-detail">
                            <div className="single-detail">
                                <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                    <br /> <br />
                                    Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                            </div>
                            <div className="single-detail">
                                <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                    <br /> <br />
                                    Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                            </div>
                            <div className="single-detail">
                                <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                    <br /> <br />
                                    Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                            </div>
                            <div className="single-detail">
                                <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                    <br /> <br />
                                    Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                            </div>
                        </div>
                    </div>
                    <div className="bottom-roadmap-mobile d-none">
                        <div className="single-content">
                            <div className="left-img">
                                <img src="\assets\roadmap\ecclipse.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="right-text">
                                <h6>PHASE 1</h6>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[6] = el)}>Q3 - 2024</h4>
                                </div>
                                <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                    <br /> <br />
                                    Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                            </div>
                        </div>
                        <div className="single-content">
                            <div className="left-img">
                                <img src="\assets\roadmap\ecclipse.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="right-text">
                                <h6>PHASE 2</h6>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[7] = el)}>Q4 - 2024</h4>
                                </div>
                                <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                    <br /> <br />
                                    Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                            </div>
                        </div>
                        <div className="single-content">
                            <div className="left-img">
                                <img src="\assets\roadmap\ecclipse.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="right-text">
                                <h6>PHASE 3</h6>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[8] = el)}>Q1 - 2025</h4>
                                </div>
                                <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                    <br /> <br />
                                    Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                            </div>
                        </div>
                        <div className="single-content">
                            <div className="left-img">
                                <img src="\assets\roadmap\ecclipse.svg" alt="img" className='img-fluid' />
                            </div>
                            <div className="right-text">
                                <h6>PHASE 4</h6>
                                <div style={{ overflow: 'hidden', position: 'relative' }}>
                                    <h4 style={{
                                        margin: 0,
                                        transform: 'translateY(120%)',
                                    }} ref={(el) => (textRefs.current[9] = el)}>Q2 - 2025</h4>
                                </div>
                                <p>Lorem ipsum dolor sit amet. Vel esse doloremque est quaerat commodi sit laudantium itaque qui rerum repellat in unde dolore.
                                    <br /> <br />
                                    Vel molestiae ipsa est animi repudiandae et fugit quisquam. </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}

export default Roadmap
